import { useLocalStorage } from "@/composables/useLocalStorage";
import { defineStore } from "pinia";
import { ref } from "vue";

export type ListStyle = "cards" | "list";

export const useUiStateStore = defineStore("uiState", () => {
  const degreeStyleLocalStorageValue = ref<ListStyle>("cards");
  const degreeDisplayStyle = ref<ListStyle>("cards");
  const storeLoading = ref<boolean>(true);

  const toggleDisplayStyle = () => {
    if (!import.meta.client) {
      return;
    }
    const localStorage = useLocalStorage();
    if (degreeDisplayStyle.value === "cards") {
      degreeStyleLocalStorageValue.value = "list";
      localStorage.setItem("display-style", "list");
    } else {
      degreeStyleLocalStorageValue.value = "cards";
      localStorage.setItem("display-style", "cards");
    }
    degreeDisplayStyle.value = degreeStyleLocalStorageValue.value;
  };
  /**
   * @summary update list style ref value and its localStorage value
   * @param val {ListStyle}
   */
  const setDisplayStyle = (val: ListStyle) => {
    if (!import.meta.client) {
      return;
    }
    const localStorage = useLocalStorage();
    degreeStyleLocalStorageValue.value = val;
    localStorage.setItem("display-style", val);
    degreeDisplayStyle.value = degreeStyleLocalStorageValue.value;
  };
  /**
   * @summary update list style ref value without manipulating localstorage value
   * @param val {ListStyle}
   */
  const setDisplayStyleRef = (val: ListStyle) => {
    degreeDisplayStyle.value = val;
  };
  /**
   * @summary sync degreeDisplayStyle value with degreeStyleLocalStorageValue without reading or manipulating localStorage.
   */
  const syncState = () => {
    degreeDisplayStyle.value = degreeStyleLocalStorageValue.value;
  };
  const initState = async () => {
    const localStorage = useLocalStorage();
    let displayStyle: ListStyle | null = await localStorage.getItem(
      "display-style"
    );
    if (!displayStyle) {
      await localStorage.setItem("display-style", "cards");
      displayStyle = await localStorage.getItem("display-style");
    }
    degreeStyleLocalStorageValue.value = displayStyle!;
    degreeDisplayStyle.value = degreeStyleLocalStorageValue.value!;
    storeLoading.value = false;
  };

  return {
    degreeDisplayStyle,
    storeLoading,
    toggleDisplayStyle,
    setDisplayStyle,
    setDisplayStyleRef,
    syncState,
    initState,
  };
});
