import { useUiStateStore } from "@/stores";

export default defineNuxtPlugin({
  name: "init-states",
  async setup(nuxtApp) {
    nuxtApp.hook("app:created", async () => {
      const uiStateStore = useUiStateStore();
      await uiStateStore.initState();
    });
  },
});
