// import { localStorageDriver } from 'unstorage/drivers/localstorage';
import { createStorage } from "unstorage";
import localStorageDriver from "unstorage/drivers/localstorage";

export const useLocalStorage = () => {
  const storage = createStorage({
    driver: localStorageDriver({ base: "local" }),
  });
  return storage;
};
