import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gab9g3XuEx from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-aos/dist/runtime/plugin.js";
import motion_fmZWau4t0U from "/opt/atlassian/pipelines/agent/build/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_u8RxmzeSAw from "/opt/atlassian/pipelines/agent/build/plugins/aos.ts";
import chatbot_client_FhoV2M6j2X from "/opt/atlassian/pipelines/agent/build/plugins/chatbot.client.ts";
import fontawesome_cn2c4tOOHz from "/opt/atlassian/pipelines/agent/build/plugins/fontawesome.ts";
import lozad_client_psXt5xjlKI from "/opt/atlassian/pipelines/agent/build/plugins/lozad.client.ts";
import pageLoadEvent_client_asFcrwrpA4 from "/opt/atlassian/pipelines/agent/build/plugins/pageLoadEvent.client.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.ts";
import storesInit_client_fPNEpfZY4n from "/opt/atlassian/pipelines/agent/build/plugins/storesInit.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_gab9g3XuEx,
  motion_fmZWau4t0U,
  chunk_reload_client_UciE0i6zes,
  aos_u8RxmzeSAw,
  chatbot_client_FhoV2M6j2X,
  fontawesome_cn2c4tOOHz,
  lozad_client_psXt5xjlKI,
  pageLoadEvent_client_asFcrwrpA4,
  sentry_client_shVUlIjFLk,
  storesInit_client_fPNEpfZY4n
]