// plugins/chatbot.ts
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (process.client) {
    // Define chatbot HTML (iframe + chat icon)
    const chatbotHtml = `
      <iframe
        id="insync-iframe"
        src="https://dbrd449anfbv4.cloudfront.net/insync_iframe_webchat_js_${
          config.public.siteEnv === "prod" ? "prod" : "staging"
        }.html"
        class="responsive-iframe"
        sandbox="allow-scripts allow-modals allow-popups allow-same-origin"
      ></iframe>
      
      <span id="insync-chat-icon-id" class="d-none d-lg-block">
        <img
          src="https://d2c0xhqyhmtkod.cloudfront.net/${
            config.public.siteEnv === "prod"
              ? "asu_chat_button_icon.png"
              : "sdk-assets/staging/asu_chat_button_icon.png"
          }"
          height="75"
          alt="Chat Icon"
          onclick="openChat()"
        />
      </span>
    `;
    // Inject the chatbot HTML into the body
    const body = document.body;
    if (body) {
      body.insertAdjacentHTML("beforeend", chatbotHtml);

      // Dynamically load the script
      const script = document.createElement("script");
      script.src = `https://dbrd449anfbv4.cloudfront.net/insync_webchat_${
        config.public.siteEnv === "prod" ? "production" : "staging"
      }.js`;
      script.async = true;

      script.onload = () => {
        console.log("Chatbot script loaded");
        // Set up the chatbot environment once the script is loaded
        if (window.insyncSetChatEnv) {
          window.insyncSetChatEnv({
            insyncMarket: "us_asu",
            insyncEnv:
              config.public.siteEnv === "prod" ? "production" : "staging",
            insyncAppIdDev: "702c1e15c41a13c70bd2b181f6a98cc1",
            insyncAppIdStage: "478b2581c1863bac4fab00cf95a39309",
            insyncAppIdProd: "48bb0fcb7b33931c620b52d6a452a053",
            insyncUserIdExpiryInDays: 3,
            insyncChatIconId: "insync-chat-icon-id",
            insyncIframeId: "insync-iframe",
            insyncHostedPageRef: window.location.href,
          });
        }
      };

      script.onerror = (error) => {
        console.error("Failed to load chatbot script", error);
      };

      document.head.appendChild(script);
    }

    // Define the openChat function on the window object
    window.openChat = () => {
      if (window.insyncSetChatEnv) {
        // Make sure you're passing the correct configuration object to openChat
        window.insyncSetChatEnv.openChat("OpenChat", "user", {
          insyncMarket: "us_asu",
          insyncEnv:
            config.public.siteEnv === "prod" ? "production" : "staging",
          insyncAppIdDev: "702c1e15c41a13c70bd2b181f6a98cc1",
          insyncAppIdStage: "478b2581c1863bac4fab00cf95a39309",
          insyncAppIdProd: "48bb0fcb7b33931c620b52d6a452a053",
          insyncUserIdExpiryInDays: 3,
          insyncChatIconId: "insync-chat-icon-id",
          insyncIframeId: "insync-iframe",
          insyncHostedPageRef: window.location.href,
        });
      } else {
        console.error("Chatbot environment is not set properly.");
      }
    };
  }
});
